<template>
  <f-container class="d-flex justify-content-around">
    <f-card
      align-v="center"
      class="mt-5 text-center"
    >
      <img
        alt=""
        src="../../assets/images/logo.png"
        style="height:130px;
        width: 180px;"
      >
      <h1 class="mt-5 font-weight-bold text-warning">
        404 <i class="fa fa-warning" />
      </h1>
      <h2 class="bg-warning mt-5 p-2">
        {{ $t('messages.pageNotFound') }}
      </h2>

      <h3 class="mt-5">
        {{ $t('messages.leftMenu') }}
      </h3>

      <router-link
        class="btn btn-secondary mt-5"
        to="/"
      >
        {{ $t('labels.home') }}
      </router-link>
    </f-card>
  </f-container>
</template>

<script>
export default {}
</script>
